<template>
    <div class="container rtl">
        <div class="row py-2">
            <div class="col">
                <form @submit.prevent="addCategory" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی جۆر</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable">
                            </label> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>ناوی جۆر</label>
                            <input v-model="category.category_name" class="form-control"
                                placeholder="ناوی جۆر" type="text" required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <label>مەخزەن</label>
                            <div class="form-group">
                                <select class="form-control" :disabled="auth.user.user_role == 'company'" v-model="category.store_id">
                                    <option v-for="(item, index) in store" :key="index" :value="item.store_id">
                                        {{item.store_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                           <div class="form-group">
                            <button type="submit" class="btn btn-primary">زیادکردن</button>
                           </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ناوی جۆر</th>
                            <th scope="col">پۆلێن</th>
                            <th scope="col">مەخزەن</th>
                            <th scope="col">وێنە</th>
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in categorys" :key="index">
                            <td>{{item.category_name}}</td>
                            <td>{{item.category_sort}}</td>
                            <td>{{item.store_name}} ({{item.city_name_ku}})</td>
                            <td>
                                <div v-if="item.category_image" @click="openImageModal(item)" :style="`width:70px; height:70px;background: url(${$api+item.category_image});background-repeat: no-repeat;background-size: contain;`">

                                </div>
                                <div v-if="!item.category_image" @click="openImageModal(item)" :style="`width:70px; height:70px;background: url('https://cdn.icon-icons.com/icons2/1369/PNG/512/-broken-image_89881.png');background-repeat: no-repeat;background-size: contain;`">

                                </div>
                                <!-- <button v-if="!item.category_image"  @click="openImageModal(item)" class="btn btn-primary">وێنە دابنێ</button> -->
                            </td>
                          
                            <td>
                                <button @click="openCategory(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="openCategory(item , 'update')" type="button" class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی جۆر</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی جۆر؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deleteCategory">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">گۆڕانکاری وێنە</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <img v-if="selected_category.category_image" style="width:100%;max-width: 700px;"  :src="$api+selected_category.category_image" alt="">
                        <input type="file" name="file" ref="file" id="file" @change="onChangeFileUpload">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <!-- <button type="button" class="btn btn-danger">گۆڕینی وێنە</button> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updateCategory">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی جۆر</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>ناوی جۆر</label>
                                    <input v-model="selected_category.category_name" class="form-control form-control-lg"
                                        placeholder="ناوی جۆر" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <label>پۆلێنی  جۆر</label>
                                    <input v-model="selected_category.category_sort" class="form-control form-control-lg"
                                        placeholder="پۆلێنی جۆر" type="text" required>
                                </div>
                              
                                <div class="col-6 my-1">
                                    <div class="form-group">
                                    <label>مەخزەن</label>
                                        <select class="form-control form-control-lg" :disabled="auth.user.user_role == 'company'" v-model="selected_category.store_id">
                                            <option v-for="(item, index) in store" :key="index" :value="item.store_id">
                                                {{item.store_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_category: {},
                category: {
                    store_id : 0,
                },
                resetable : false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                },
                file: ''
            }
        },
        computed: {
            categorys() {
                return this.$store.getters.category
            },
            store() {
                return this.$store.getters.store
            },
            auth() {
                return this.$store.getters.auth
            },
        },
        methods: {
            uploadImage() { 
                let formData = new FormData();
                formData.append('category_id', this.selected_category.category_id);
                formData.append('file', this.file);
                axios.post("category/updateImage.php",
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((data) => {
                        console.log(data);
                        if (data.data.status == 'ok') {
                            this.getCategorys()
                            $('#imageModal').modal('hide')
                            this.bar = {
                                model: true,
                                text: 'Image Changed',
                                color: 'blue'
                            }
                        }
                    })
                    .catch(() => {
                        console.log('FAILURE!!');
                    });
            },
            openImageModal(item){
                $('#imageModal').modal('show')
                this.selected_category = item
            },
            onChangeFileUpload() {
                this.file = this.$refs.file.files[0];
                console.log(this.file);
                this.uploadImage()
            },
            reset(){
                this.category = {
                    store_id : 0
                }
            },
            getCategorys() {
                $('#dataTable').DataTable().destroy();
                axios.post('category/read.php' , {
                    store_id : this.auth.user.store_id,
                    user_role : this.auth.user.user_role == 'company' ? 'company' : null,
                })
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.category = r.data.data
                            this.$nextTick( () =>{
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addCategory() {
                let params = this.category
                axios.post('category/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                                this.reset()
                            }
                            this.getCategorys()
                            this.msg = {
                                model: true,
                                text: 'جۆر زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deleteCategory() {
                let params = {
                    category_id: this.selected_category.category_id
                }
                axios.post('category/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getCategorys()
                            this.msg = {
                                model: true,
                                text: ' جۆر سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updateCategory() {
                let params = this.selected_category
                axios.post('category/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getCategorys()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'جۆر نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openCategory(item, type) {
                this.selected_category = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            // this.getCategorys()
            if (this.auth.isAuth) {
                this.category.store_id = this.auth.user.store_id
            }
            $('#dataTable').DataTable();
        },
    }
</script>